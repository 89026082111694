import React from 'react'
import Allroutes from './Components/Allroutes'
import Header from './Components/Header'




const App = () => {
  return (
    <div>
     <Allroutes />
    </div>
  )
}

export default App